import { createSlice } from '@reduxjs/toolkit'
// import appConfig from 'configs/app.config'


const initialState = {
    currentLang: 'en',
}

export const localeSlice = createSlice({
    name: 'locale',
    initialState,
    reducers: {
        setLang: (state, action) => {
            const supportedLangs = ['en', 'fr', 'de', 'ar', 'it']
            if (supportedLangs?.includes(action.payload)) {
                state.currentLang = action.payload
            } else {
                state.currentLang = 'en'
            }
        },
    },
})

export const { setLang } = localeSlice.actions

export default localeSlice.reducer
