import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    products: [],
    paymentSummary: {
        overallTotalFee: 0,
        overallTotalRecurringFee: 0,
        overallTotal: 0,
        overallTotalParentRefDiscountFee: 0,
        overAllTotalRecurringParentRefDiscountFee: 0,
    },
}

export const basketProductsSlice = createSlice({
    name: 'cart-swik/products',
    initialState,
    reducers: {
        addProduct: (state, action) => {
            const newProduct = action.payload

            // Ensure the new product includes the checkbox state property
            const productWithCheckboxState = {
                ...newProduct,
                subscription_checkbox_state: [], // Initial checkbox state (you can set it to an initial value if needed)
                discount_highlight_state: false,
            }

            return {
                ...state,
                products: [...state.products, productWithCheckboxState],
            }
        },
        updateProduct: (state, action) => {
            const updatedProducts = state.products.map((product) => {
                if (
                    product.id === action.payload.id &&
                    product.metadata.address.value ===
                        action.payload.metadata.address.value
                ) {
                    return action.payload
                }
                return product
            })
            return { ...state, products: updatedProducts }
        },

        // Remove product from cart
        removeProduct: (state, action) => {
            const filteredProducts = state.products.filter(
                (product) => product?.metadata?.courseId !== action.payload
            )
            return { ...state, products: filteredProducts }
        },
        removeAllProductsWithSameID: (state, action) => {
            const filteredProducts = state.products.filter(
                (product) => product.id !== action.payload
            )
            return { ...state, products: filteredProducts }
        },
        removeSingleProductOnceOfSameID: (state, action) => {
            let removed = false

            const updatedProducts = state.products.filter((product) => {
                if (!removed && product.id === action.payload) {
                    removed = true
                    return false // Exclude the first occurrence
                }
                return true
            })

            return { ...state, products: updatedProducts }
        },
        removeAllProducts: (state) => {
            return { ...state, products: [] }
        },
        setProducts: (state, action) => {
            return { ...state, products: action.payload }
        },

        // Update product subscription status
        updateProductSubcriptionStatus: (state, action) => {
            const { productCode, productAddress, subscriptionNameToSetTrue } =
                action.payload

            const updatedProducts = state?.products.map((product) => {
                if (
                    product?.code === productCode &&
                    product?.metadata?.address?.value === productAddress
                ) {
                    // Update subscription status based on timescale
                    const updatedSubscriptionOptions =
                        product.subscription_options.map((option) => ({
                            ...option,
                            subscription_confirmed:
                                // option.timescale === timescaleToSetTrue,
                                option.name === subscriptionNameToSetTrue,
                        }))
                    // Create a new product object with updated subscription options
                    return {
                        ...product,
                        subscription_options: updatedSubscriptionOptions,
                    }
                } else {
                    return { ...product }
                }
            })
            return { ...state, products: updatedProducts }
        },

        // Update product subscription status
        updateProductDiscountHighlightState: (state, action) => {
            const { productCode, productAddress } = action.payload

            const productIndex = state.products.findIndex((product) => {
                return (
                    product?.code === productCode &&
                    product?.metadata?.address?.value === productAddress
                )
            })

            if (productIndex !== -1) {
                if (
                    state.products[productIndex]?.subscription_options.every(
                        (option) => {
                            return option.subscription_confirmed === false
                        }
                    )
                ) {
                    state.products[productIndex].discount_highlight_state = true
                } else {
                    state.products[
                        productIndex
                    ].discount_highlight_state = false
                }
            }
        },

        updateCheckboxState: (state, action) => {
            const { productCode, productAddress, checkboxState } =
                action.payload
            const productIndex = state.products.findIndex((product) => {
                return (
                    product?.code === productCode &&
                    product?.metadata?.address?.value === productAddress
                )
            })

            if (productIndex !== -1) {
                state.products[productIndex].subscription_checkbox_state =
                    checkboxState
            }
        },
        clearProductSlice: (state, action) => {
            return initialState
        },

        // New reducer to calculate and update paymentSummary
        calculatePaymentSummary: (state) => {
            // Use reduce to calculate overall totals
            const {
                overallTotalFee,
                overallTotalRecurringFee,
                overallTotalParentRefDiscountFee,
                overAllTotalRecurringParentRefDiscountFee,
            } = state?.products?.reduce(
                (acc, item) => {
                    if(item?.price?.length>0){
                    // Get the total fee for the product
                    const totalFee = parseFloat(item?.price[0]?.value?.amount) || 0
                    const totalParentRefDiscountFee =
                        parseFloat(item?.price[0]?.value?.discount_amount) || 0
                    // Find the subscription option with subscription_confirmed set to true
                    const confirmedSubscription =
                        item.subscription_options.find(
                            (option) => option.subscription_confirmed
                        )

                    // Get the recurring fee for the product
                    const recurringFee =
                        (confirmedSubscription
                            ? parseFloat(
                                  confirmedSubscription.price[0].value.amount
                              )
                            : 0) || 0
                    const recurringParentRefDiscountFee =
                        (confirmedSubscription
                            ? parseFloat(
                                  confirmedSubscription.price[0].value
                                      .discount_amount
                              )
                            : 0) || 0

                    // Add the individual product totals to overall totals
                    acc.overallTotalFee += totalFee
                    acc.overallTotalRecurringFee += recurringFee
                    acc.overallTotalParentRefDiscountFee +=
                        totalParentRefDiscountFee
                    acc.overAllTotalRecurringParentRefDiscountFee +=
                        recurringParentRefDiscountFee
                    // Return the accumulator for the next iteration
                    return acc
                }},
                {
                    overallTotalFee: 0,
                    overallTotalRecurringFee: 0,
                    overallTotalParentRefDiscountFee: 0,
                    overAllTotalRecurringParentRefDiscountFee: 0,
                }
            )

            // Update overall totals in the state
            state.paymentSummary = {
                overallTotalFee,
                overallTotalRecurringFee,
                overallTotal: overallTotalFee + overallTotalRecurringFee,
                overallTotalParentRefDiscountFee,
                overAllTotalRecurringParentRefDiscountFee,
            }
        },
    },
})

export const {
    addProduct,
    updateProduct,
    removeAllProductsWithSameID,
    removeSingleProductOnceOfSameID,
    removeProduct,
    removeAllProducts,
    setProducts,
    updateCheckboxState,
    updateProductDiscountHighlightState,
    updateProductSubcriptionStatus,
    clearProductSlice,
    calculatePaymentSummary,
} = basketProductsSlice.actions
export default basketProductsSlice.reducer
