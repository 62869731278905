// 'use client'

// import React from 'react'
// import ReactDOM from 'react-dom/client'

// import './index.css'
// import App from './App'
// import reportWebVitals from './reportWebVitals'
// import { GoogleOAuthProvider } from '@react-oauth/google'
// import { PersistGate } from 'redux-persist/integration/react'
// import { Provider } from 'react-redux'
// import { BrowserRouter } from 'react-router-dom'
// import store, { persistor } from './store'
// import history from './history'
// import MyApp from './App'

// import { RealmProvider } from './RealmContext';

// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(
//   // Commenting out GoogleOAuthProvider for now but can be uncommented if needed
//   // <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_PROVIDER_CLIENT_ID}>
//   <Provider store={store}>
//   <RealmProvider>
//     <PersistGate loading={null} persistor={persistor}>
//       <BrowserRouter history={history}>

//           <MyApp />

//       </BrowserRouter>
//     </PersistGate>
//   </RealmProvider>
//   </Provider>
//   // </GoogleOAuthProvider>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from 'react'
import { hydrate, render } from 'react-dom'
import { hydrateRoot } from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import store, { persistor } from './store'
import history from './history'
import MyApp from './App'
import { RealmProvider } from './RealmContext'

const rootElement = document.getElementById('root')





const app = (
    <Provider store={store}>
        <RealmProvider>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter history={history}>
                    <MyApp />
                </BrowserRouter>
            </PersistGate>
        </RealmProvider>
    </Provider>
)
 
    hydrate(app, rootElement, () => {
        console.log('Bundle is loaded')
    })


// }

// Measure performance if needed
reportWebVitals()
