// RealmContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAppInstance } from 'store/appInstance/appSlice';

const RealmContext = createContext();

export const useRealm = () => useContext(RealmContext);

export const RealmProvider = ({ children }) => {
    const [realm, setRealm] = useState(null);
    const [app, setApp] = useState(null);
    const dispatch = useDispatch();

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = "https://unpkg.com/realm-web@2.0.0";
    //     script.async = true;
    //     script.onload = () => {
    //         const loadedRealm = window.Realm;
    //         setRealm(loadedRealm);
    //         const appInstance = new loadedRealm.App({ id: process.env.REACT_APP_APP_ID });
    //         setApp(appInstance);
    //         dispatch(setAppInstance(appInstance));
    //     };
    //     document.body.appendChild(script);
    // }, [dispatch]);
    useEffect(() => {
        // Function to load the Realm Web script
        const loadRealmScript = () => {
            // Create a new script element
            const script = document.createElement('script');
            // Set the script source to the Realm Web package
            script.src = "https://unpkg.com/realm-web@2.0.0";
            // Set the script to load asynchronously
            script.async = true;
            // Define what happens when the script is loaded
            script.onload = () => {
                // Access the loaded Realm library from the global window object
                const loadedRealm = window.Realm;
                // Set the realm state to the loaded Realm library
                setRealm(loadedRealm);
                // Create a new Realm App instance with the provided app ID
                const appInstance = new loadedRealm.App({ id: process.env.REACT_APP_APP_ID });
                // Set the app state to the created Realm App instance
                setApp(appInstance);
                // Dispatch an action to store the app instance in the Redux store
                dispatch(setAppInstance(appInstance));
            };
            // Append the script element to the document body
            document.body.appendChild(script);
        };
    
        // Check if the document is already fully loaded
        if (document.readyState === 'complete') {
            // If the document is fully loaded, load the Realm script immediately
            loadRealmScript();
        } else {
            // If the document is not fully loaded, add an event listener to load the script once the page loads
            window.addEventListener('load', loadRealmScript);
            // Cleanup function to remove the event listener when the component unmounts
            return () => window.removeEventListener('load', loadRealmScript);
        }
    }, [dispatch]);
    

    return (
        <RealmContext.Provider value={{ realm, app, Credentials: realm?.Credentials, App: realm?.App }}>
            {children}
        </RealmContext.Provider>
    );
};
