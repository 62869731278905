import { createSlice } from '@reduxjs/toolkit'

export const initialState = {}
export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => {
            return action.payload
        },
        setUserCanResetPassword: (state, action) => {
            return {
                ...state,
                resetPasswordEmail: action.payload,
                canResetPassword: true,
            }
        },
        clearResetPasswordDetails: (state, _) => {
            return {
                ...state,
                resetPasswordEmail: '',
                canResetPassword: false,
            }
        },
        userLoggedOut: () => initialState,
    },
})

export const {
    setUser,
    userLoggedOut,
    setUserCanResetPassword,
    clearResetPasswordDetails,
} = userSlice.actions

export default userSlice.reducer
