import { useSelector } from 'react-redux'

function useAuth() {
    const user = useSelector((state) => state.auth.user)

    return {
        authenticated: user?.email,
    }
}

export default useAuth
