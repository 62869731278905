import OneSignal from 'react-onesignal'
import { runClientDebug } from 'utils/runClientDebug'

export const init = async () => {
    try {
        // console.log('ONESIGNAL APP ID', process.env.REACT_APP_ONESIGNAL_APP_ID)
        // OneSignal.init({
        //     appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
        // })
        // OneSignal.Slidedown.promptPush()
    } catch (err) {
        runClientDebug(err)
    }
}

export const communication_callback = async (event) => {
    if (event.type === 'login') {
        // if (user) {
        //     try {
        //         console.log('USER', user)
        //         await user.functions.fn_onesignal_user_create({
        //             subscription_type: 'Email',
        //         })
        //         // Login OneSignal user after logging out
        //         // await loginUser(user)
        //         // Add subscription to push notifications
        //         // await enablePushNotifications()
        //         // Add OneSignal logged in user email
        //         // await addUserEmail(user)
        //         // Adding push notification subscription to the user
        //         // await OneSignal.User.PushSubscription.optIn()
        //         // Update OneSignal User Profile
        //         // updateUserprofile(currentUser, email, first_name, last_name)
        //         // await updateUserprofile(user, false, true, true)
        //         // await updateUserNotificationSetting(user)
        //     } catch (error) {
        //         runClientDebug(error)
        //     }
        // }
    } else if (event.type === 'logout') {
        runClientDebug(event.type)
        // Logout OneSignal user before logging in
        // await logoutUser()
        // window.location.reload()
        // window.OneSignal = false

        // await init()
    }
}
export const loginUser = async (user) => {
    try {
        if (user.customData.uuid) {
            await OneSignal.login(user.customData.uuid)
        }
    } catch (error) {
        console.error(error)
        return null
    }
}

export const logoutUser = async () => {
    try {
        await OneSignal.logout()
    } catch (error) {
        console.error(error)
        return null
    }
}

export const addUserEmail = async (user) => {
    try {
        if (user.customData.email) {
            OneSignal.User.addEmail(user.customData.email)
        }
    } catch (error) {
        console.error(error)
        return null
    }
}

export const enablePushNotifications = async () => {
    try {
        await OneSignal.User.PushSubscription.optIn()

        runClientDebug('User has been opted in for push notifications.')
        // Perform additional actions if needed
    } catch (error) {
        runClientDebug('Error while opting in for push notifications:', error)
        // Handle the error
    }
}

export const lt = async (
    user,
    setEmail = false,
    setFirstName = false,
    setLastName = false
) => {
    if (setEmail) {
        OneSignal.User.addEmail(user.customData.email)
    }

    // if (setFirstName && user.customData.firstName) {
    //     OneSignal.User.addTag('first_name', user.customData.firstName)
    // }
    // if (setLastName && user.customData.lastName) {
    //     OneSignal.User.addTag('last_name', user.customData.lastName)
    // }

    //plus anything else we can add in there
}

export const createCommunicationSubscriptions = async (
    setMarketing = false,
    setTransactionsal = false
) => {
    //Add subscription code here
}

export const updateUserNotificationSetting = async (user) => {
    try {
        const notification = user?.customData?.preferences?.notification
        if (user.customData && notification) {
            if (notification) {
                const combinedTags = Object.keys(notification?.email)
                    .filter((key) => {
                        return notification?.email[key]
                    })
                    .join('_')

                const finalTag = {
                    notification: combinedTags || 'none',
                }

                OneSignal.User.addTags(finalTag)
            }
        }
    } catch (error) {
        console.error(error)
        return null
    }
}
