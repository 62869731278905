import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const currentCourseDetailSlice = createSlice({
    name: 'currentCourseDetail',
    initialState,
    reducers: {
        addCurrentCourseDetail: (state, action) => {
            const course = action.payload

            return {
                ...state,
                course,
            }
        },

        addCurrentCourseData: (state, action) => {
            const data = action.payload?.version

            return {
                ...state,
                course: {
                    ...state.course,
                    data,
                },
            }
        },

        addCurrentCourseCustomData: (state, action) => {
  
            const { key, value } = action.payload;

            return {
                ...state,
                course: {
                  ...state.course,
                  [key]: value,  // Dynamically assigns the string key to the value
                }
            }
        },


        clearCurrentCourseData: (state) => {
            return {
                ...state,
                course: {
                    ...state.course,
                    data: {},
                },
            }
        },

        removecurrentCourseDetail: (state) => {
            return {}
        },
    },
})

export const {
    addCurrentCourseDetail,
    addCurrentCourseData,
    addCurrentCourseCustomData,
    clearCurrentCourseData,
    removecurrentCourseDetail,
} = currentCourseDetailSlice.actions
export default currentCourseDetailSlice.reducer
