import { AUTH_PREFIX_PATH } from 'constants/route.constant'

function clearLocalStorage(requestType) {
    localStorage.removeItem('authentication_required_for_product_report')
    localStorage.removeItem('authentication_required')

    if (requestType === 'reportsPageRequest') {
        localStorage.removeItem('purchasesPageRequest')
    } else if (requestType === 'purchasesPageRequest') {
        localStorage.removeItem('reportsPageRequest')
    }
}

function getAppConfigInternal(lang) {
    const coursePurchased = localStorage.getItem('course_purchased')
    // Function for extracting ref values from the URL
    function getLastPartOfUrl() {
        let refValue = ''
        const queryParams = new window.URLSearchParams(window.location.search)
        if (queryParams.has('ref')) {
            refValue = queryParams.get('ref')
            return refValue.startsWith('a_aid=')
                ? refValue
                : 'a_aid=' + refValue
        } else if (queryParams.has('a_aid')) {
            refValue = 'a_aid=' + queryParams.get('a_aid')
            return refValue
        }
        const hashParams = new window.URLSearchParams(
            window.location.hash.substring(1)
        )
        if (hashParams.has('ref')) {
            refValue = hashParams.get('ref')
            return refValue.startsWith('a_aid=')
                ? refValue
                : 'a_aid=' + refValue
        } else if (hashParams.has('a_aid')) {
            refValue = 'a_aid=' + hashParams.get('a_aid')
            return refValue
        }
        const pathSegments = window.location.pathname.split('/')
        if (
            pathSegments.length >= 2 &&
            pathSegments[pathSegments.length - 2] === 'ref'
        ) {
            refValue = pathSegments[pathSegments.length - 1]
            return refValue.startsWith('a_aid=')
                ? refValue
                : 'a_aid=' + refValue
        }
        const currentUrl = window.location.href
        const params = new window.URLSearchParams(
            new URL(currentUrl).hash.slice(1)
        )
        if (params.has('a_aid')) {
            refValue = `a_aid=${params.get('a_aid')}`
        }
        return refValue
    }

    let parentRef = getLastPartOfUrl()
    if (parentRef && process.env.REACT_APP_FORCE_REF_SIGNUP === 'TRUE') {
        localStorage.setItem('parentRef', parentRef)
    }

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    let loggedInOnce = localStorage.getItem('loggedInOnce')
    const authentication_required = localStorage.getItem(
        'authentication_required'
    )
    const authenticationRequiredForProductReportDownload = localStorage.getItem(
        'authentication_required_for_product_report'
    )
    let isReportsPageRequest = localStorage.getItem('reportsPageRequest')
    let isPurchasesPageRequest = localStorage.getItem('purchasesPageRequest')
    const reportsPageRequest = urlParams.get('reportsPageRequest')
    const purchasesPageRequest = urlParams.get('purchasesPageRequest')

    if (reportsPageRequest) {
        if (!localStorage.getItem('reportsPageRequest')) {
            localStorage.setItem('reportsPageRequest', true)
            clearLocalStorage('reportsPageRequest')
        }
    } else if (purchasesPageRequest) {
        if (!localStorage.getItem('purchasesPageRequest')) {
            localStorage.setItem('purchasesPageRequest', true)
            clearLocalStorage('purchasesPageRequest')
        }
    }

    let language = lang || 'en'
    const appConfig = {
        apiPrefix: '/api',
        authenticatedEntryPath: authentication_required
            ? `/${language}/report-address`
            : isPurchasesPageRequest
            ? `/${language}/my-account/purchases`
            : isReportsPageRequest
            ? `/${language}/my-account/reports`
            : authenticationRequiredForProductReportDownload
            ? `/${language}/report`
            : coursePurchased==="true"
            ? `/${language}/my-account/courses`
            : `/${language}/cart`,
        unAuthenticatedEntryPath:
            (purchasesPageRequest || reportsPageRequest) && loggedInOnce
                ? `${AUTH_PREFIX_PATH}/${language}/account`
                : (purchasesPageRequest || reportsPageRequest) && !loggedInOnce
                ? `${AUTH_PREFIX_PATH}/${language}/register`
                : loggedInOnce
                ? `${AUTH_PREFIX_PATH}/${language}/account`
                : `${AUTH_PREFIX_PATH}/${language}/register`,
        tourPath: '/',
        locale: 'en',
        enableMock: false,
    }
    return appConfig
}

export function getAppConfig(lang, coursePurchasedCheck) {
   

        return getAppConfigInternal(lang, coursePurchasedCheck)
  
}
