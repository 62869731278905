import React, {
    forwardRef,
    useRef,
    useEffect,
    useState,
    useImperativeHandle,
    Fragment,
} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Table, Pagination, Checkbox, Alert } from 'components/ui'
// import TableRowSkeleton from './loaders/TableRowSkeleton'
import Loading from './Loading'
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    getExpandedRowModel,
    flexRender,
} from '@tanstack/react-table'
import SubDataTable from './SubDataTable'

const { Tr, Th, Td, THead, TBody, Sorter } = Table

const IndeterminateCheckbox = (props) => {
    const {
        indeterminate,
        onChange,
        onCheckBoxChange,
        onIndeterminateCheckBoxChange,
        ...rest
    } = props

    const ref = useRef(null)

    useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, indeterminate])

    const handleChange = (e) => {
        onChange(e)
        onCheckBoxChange?.(e)
        onIndeterminateCheckBoxChange?.(e)
    }

    return (
        <Checkbox
            className="mb-0"
            ref={ref}
            onChange={(_, e) => handleChange(e)}
            {...rest}
        />
    )
}

const DataTable = forwardRef((props, ref) => {
    const {
        columns: columnsProp,
        data,
        loading,
        onPaginationChange,
        onSort,
        pagingData,
        expandSubColumns,
        subColumns,
    } = props

    const { pageSize, pageIndex, total } = pagingData

    const [sorting, setSorting] = useState(null)

    // const handleCheckBoxChange = (checked, row) => {
    //     if (!loading) {
    //         onCheckBoxChange?.(checked, row)
    //     }
    // }

    // const handleIndeterminateCheckBoxChange = (checked, rows) => {
    //     if (!loading) {
    //         onIndeterminateCheckBoxChange?.(checked, rows)
    //     }
    // }

    const handlePaginationChange = (page) => {
        if (!loading) {
            onPaginationChange?.(page)
        }
    }

    useEffect(() => {
        if (Array.isArray(sorting)) {
            const sortOrder =
                sorting.length > 0 ? (sorting[0].desc ? 'desc' : 'asc') : ''
            const id = sorting.length > 0 ? sorting[0].id : ''
            onSort?.({ order: sortOrder, key: id })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorting])

    const hasOldColumnMetaKey = columnsProp.some(
        (col) => col.Header || col.accessor || col.Cell
    )

    // const finalColumns = useMemo(() => {
    //     const columns = columnsProp

    //     if (selectable) {
    //         return [
    //             {
    //                 id: 'select',
    //                 header: ({ table }) => (
    //                     <IndeterminateCheckbox
    //                         checked={table.getIsAllRowsSelected()}
    //                         indeterminate={table.getIsSomeRowsSelected()}
    //                         onChange={table.getToggleAllRowsSelectedHandler()}
    //                         onIndeterminateCheckBoxChange={(e) => {
    //                             handleIndeterminateCheckBoxChange(
    //                                 e.target.checked,
    //                                 table.getRowModel().rows
    //                             )
    //                         }}
    //                     />
    //                 ),
    //                 cell: ({ row }) => (
    //                     <IndeterminateCheckbox
    //                         checked={row.getIsSelected()}
    //                         disabled={!row.getCanSelect()}
    //                         indeterminate={row.getIsSomeSelected()}
    //                         onChange={row.getToggleSelectedHandler()}
    //                         onCheckBoxChange={(e) =>
    //                             handleCheckBoxChange(
    //                                 e.target.checked,
    //                                 row.original
    //                             )
    //                         }
    //                     />
    //                 ),
    //             },
    //             ...columns,
    //         ]
    //     }
    //     return columns
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [columnsProp, selectable])

    const [subDatas, setSubDatas] = useState([])
    // const [groupDataByUPRN, setGroupDataByUPRN] = useState([])
    useEffect(() => {
        const subData = data.map((val) => {
            console.log('VAL ****', val)
            return {
                ...val.products[0],
                created_at: val.created_at,
                status: val.status,
                order_id: val.order_id,
            }
        })
        setSubDatas(subData)

        // const groupedByUprn = subData.reduce((acc, purchase) => {
        //     const {
        //         order_id,
        //         metadata: {
        //             completeAddressInfo: { uprn },
        //         },
        //     } = purchase
        //     const sameUprnPurchases = subData.filter(
        //         (p) =>
        //             p.metadata.completeAddressInfo.uprn === uprn &&
        //             p.order_id !== order_id
        //     )

        //     if (
        //         sameUprnPurchases.length > 0 &&
        //         !acc.some(
        //             (doc) => doc.metadata.completeAddressInfo.uprn === uprn
        //         )
        //     ) {
        //         acc.push({ ...purchase, sameUprnPurchases })
        //     } else if (sameUprnPurchases.length === 0) {
        //         acc.push({ ...purchase })
        //     }
        //     return acc
        // }, [])

        // setGroupDataByUPRN(groupedByUprn)
    }, [data])

    const table = useReactTable({
        data: subDatas,
        columns: subColumns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getRowCanExpand: () => (expandSubColumns ? true : false),
        manualPagination: true,
        manualSorting: true,
        onSortingChange: setSorting,
        state: {
            sorting,
        },
    })

    const resetSorting = () => {
        table.resetSorting()
    }

    const resetSelected = () => {
        table.toggleAllRowsSelected(false)
    }

    useImperativeHandle(ref, () => ({
        resetSorting,
        resetSelected,
    }))

    if (hasOldColumnMetaKey) {
        const message =
            'You are using old react-table v7 column config, please use v8 column config instead, refer to our demo or https://tanstack.com/table/v8'

        if (
            process.env.NODE_ENV === process.env.REACT_APP_ENV_DEVELOPMENT ||
            process.env.REACT_APP_ENV === process.env.REACT_APP_ENV_DEVELOPMENT
        ) {
            console.warn(message)
        }

        return <Alert>{message}</Alert>
    }

    return (
        <Loading loading={loading && data.length !== 0} type="cover">
            <Table>
                <THead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <Th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                    >
                                        {header.isPlaceholder ? null : (
                                            <div
                                                className={classNames(
                                                    header.column.getCanSort() &&
                                                        'cursor-pointer select-none point',
                                                    loading &&
                                                        'pointer-events-none'
                                                )}
                                                onClick={header.column.getToggleSortingHandler()}
                                            >
                                                {flexRender(
                                                    header.column.columnDef
                                                        .header,
                                                    header.getContext()
                                                )}
                                                {header.column.getCanSort() && (
                                                    <Sorter
                                                        sort={header.column.getIsSorted()}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </Th>
                                )
                            })}
                        </Tr>
                    ))}
                </THead>
                {loading && data.length === 0 ? (
                    <Fragment></Fragment>
                ) : (
                    <TBody>
                        {table
                            .getRowModel()
                            .rows.slice(0, pageSize)
                            .map((row) => {
                                return (
                                    <Fragment key={row.id}>
                                        <Tr
                                            key={row.id}
                                            className={
                                                row.getIsExpanded() &&
                                                `!border !border-t-emerald-800 !border-l-emerald-800 !border-r-emerald-800`
                                            }
                                        >
                                            {row
                                                .getVisibleCells()
                                                .map((cell) => {
                                                    return (
                                                        <Td key={cell.id}>
                                                            {flexRender(
                                                                cell.column
                                                                    .columnDef
                                                                    .cell,
                                                                cell.getContext()
                                                            )}
                                                        </Td>
                                                    )
                                                })}
                                        </Tr>
                                        {row.getIsExpanded() && (
                                            <Tr>
                                                {/* 2nd row is a custom 1 cell row */}
                                                <Td
                                                    className="!border !border-b-emerald-800 !border-l-emerald-800 !border-r-emerald-800 "
                                                    colSpan={
                                                        row.getVisibleCells()
                                                            .length
                                                    }
                                                >
                                                    <SubDataTable
                                                        row={
                                                            row?.original
                                                                ?.sameUprnPurchases ||
                                                            []
                                                        }
                                                        subColumns={subColumns}
                                                    />
                                                </Td>
                                            </Tr>
                                        )}
                                    </Fragment>
                                )
                            })}
                    </TBody>
                )}
            </Table>
            <div className="flex items-center justify-between mt-4">
                <Pagination
                    pageSize={pageSize}
                    currentPage={pageIndex}
                    total={total}
                    onChange={handlePaginationChange}
                />
                <div style={{ minWidth: 130 }}>
                    {/* <Select
                        size="sm"
                        menuPlacement="top"
                        isSearchable={false}
                        value={pageSizeOption.filter(
                            (option) => option.value === pageSize
                        )}
                        options={pageSizeOption}
                        onChange={(option) => handleSelectChange(option.value)}
                    /> */}
                </div>
            </div>
        </Loading>
    )
})

DataTable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    loading: PropTypes.bool,
    onCheckBoxChange: PropTypes.func,
    onIndeterminateCheckBoxChange: PropTypes.func,
    onPaginationChange: PropTypes.func,
    onSelectChange: PropTypes.func,
    onSort: PropTypes.func,
    pageSizes: PropTypes.arrayOf(PropTypes.number),
    selectable: PropTypes.bool,
    skeletonAvatarColumns: PropTypes.arrayOf(PropTypes.number),
    skeletonAvatarProps: PropTypes.object,
    pagingData: PropTypes.shape({
        total: PropTypes.number,
        pageIndex: PropTypes.number,
        pageSize: PropTypes.number,
    }),
}

DataTable.defaultProps = {
    pageSizes: [10, 25, 50, 100],
    pagingData: {
        total: 0,
        pageIndex: 1,
        pageSize: 10,
    },
    data: [],
    columns: [],
    selectable: false,
    loading: false,
}

export default DataTable
