// App.js
import React, { useEffect, useState } from 'react'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import { useDispatch, useSelector } from 'react-redux'
import useLanguageClass from 'utils/hooks/useLanguageClass'
import { runClientDebug } from 'utils/runClientDebug'

import { init } from 'communication_engagement'
import { userLoggedOut } from 'store/auth/userSlice'
import { useRealm } from './RealmContext'

import { useNavigate } from 'react-router-dom'
import { setLang } from 'store/locale/localeSlice'
import { dateLocales } from 'locales/index-swik.js'
import dayjs from 'dayjs'
import i18n from 'i18next'

import './locales'

function MyApp() {
    // This is for debugging, this is how we can only enable console in dev mode and not display them in prod mode
    if (
        process.env.NODE_ENV === process.env.REACT_APP_ENV_DEVELOPMENT ||
        process.env.REACT_APP_ENV === process.env.REACT_APP_ENV_DEVELOPMENT
    ) {
        window.clientdebug = true
    }

    runClientDebug('dev_mode')
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const locale = useSelector((state) => state.locale.currentLang)

    const lang = useSelector((state) => state.locale.currentLang)

    const { app } = useRealm()

    // This useeffect is for if there is no app.cuurrentuser then logout the user
    useEffect(() => {
        if (app && !app?.currentUser) {
            localStorage.removeItem('course_purchased')
            dispatch(userLoggedOut())
            app?.currentUser?.logOut()
            // navigate(`/auth/${lang}/account`);
        }
    }, [app, dispatch])

    // This useEffect is to append app obj on the window object so that we can use it in the app
    useEffect(() => {
        window.app = app
    }, [app])

    // This useEffect is for to verify currentUser and to refresh the user custom data
    useEffect(() => {
        if (app?.currentUser) {
            app?.currentUser?.refreshCustomData()
        }
    }, [app])

    // This useEffect is for to add this isUserLoggedIn property into local storage if the user is logged in to work the application properly
    useEffect(() => {
        if (app && app?.currentUser?.customData) {
            localStorage.setItem('isUserLoggedIn', true)
        }
    }, [app])

    useLanguageClass(lang)

    const updateSlug = (oldSlug, newSlug) => {
        // Get the current location
        const { pathname } = window.location

        // Replace the old slug with the new one
      
        const updatedPathname = pathname.replace(
            new RegExp(`/${oldSlug}/`),
            `/${newSlug}/`
        )

        
        // Push the updated route
        navigate(updatedPathname)
    }
    function removePreviousLangClassAndAddNew(lang) {
        // Remove the previous language class from the body
        const langClass = Array.from(document.body.classList).filter((cls) =>
            cls.startsWith('lang-')
        )
        if (langClass.length > 0) {
            document.body.classList.remove(langClass)
        }
        // Add the new language class to the body
        document.body.classList.add(`lang-${lang}`)
    }
    const onLanguageSelect = async (lang) => {
        // hit api here
        const res = await app?.currentUser?.functions?.fn_user_update_language(
            lang
        )
        // here we still need to change the lan
        if (res?.status === 200 || !app?.currentUser) {
            
            updateSlug(locale, lang)

            removePreviousLangClassAndAddNew(lang)
            const formattedLang = lang.replace(/-([a-z])/g, function (g) {
                return g[1].toUpperCase()
            })

            const dispatchLang = () => {
                i18n.changeLanguage(formattedLang)
                dispatch(setLang(lang))

                // Add the language to the OneSignal user
                // OneSignal.User.addTag('current_lang', lang)
            }

            dateLocales[formattedLang]()
                .then(() => {
                    dayjs.locale(formattedLang)
                    dispatchLang()
                })
                .catch((_) => {
                    dispatchLang()
                })
        }
    }
    // This use effect will work for to do all stuff which is being executed on language selector.
    useEffect(() => {
        if (app) {
            // let newLang = lang

            // Extract base path and target route from currentUrl
            const urlParts = window.location.href.split('/')

            // const basePath = urlParts[1] // Assuming second part is the base path (adjust as needed)
            // const target = urlParts[2] // Assuming third part is the target route (adjust as needed)
            let langPart
            // Build new URL using template literals
            let newUrl
            // this is for auth urls like sigin etc
            if (urlParts[3] === 'auth') {
                newUrl =
                    '/' +
                    urlParts[3] +
                    '/' +
                    lang +
                    '/' +
                    (urlParts[5] ? urlParts[5] : 'home')

                langPart = urlParts[4]
            }
            // this is for normal urls
            else {
                // if user come for some ref url, there we dont have lang in url, so dont do any thing in that case.
                if (urlParts[3] === 'ref') {
                    // do nothing;
                    return
                } else {
                    newUrl =
                        '/' + lang + '/' + (urlParts[4] ? urlParts[4] : 'home')
                    langPart = urlParts[3]
                }
            }

            if (langPart !== lang) {
                // navigate(newUrl)
               
                onLanguageSelect(langPart)
            }
        }
    }, [app, lang])

    // useEffect(() => {
    //     // let newLang = lang

    //     // Extract base path and target route from currentUrl
    //     const urlParts = window.location.href.split('/')

    //     // const basePath = urlParts[1] // Assuming second part is the base path (adjust as needed)
    //     // const target = urlParts[2] // Assuming third part is the target route (adjust as needed)
    //     let langPart
    //     // Build new URL using template literals
    //     let newUrl
    //     // this is for auth urls like sigin etc
    //     if (urlParts[3] === 'auth') {
    //         newUrl =
    //             '/' +
    //             urlParts[3] +
    //             '/' +
    //             lang +
    //             '/' +
    //             (urlParts[5] ? urlParts[5] : 'home')

    //         langPart = urlParts[4]
    //     }
    //     // this is for normal urls
    //     else {
    //         // if user come for some ref url, there we dont have lang in url, so dont do any thing in that case.
    //         if (urlParts[3] === 'ref') {
    //             // do nothing;
    //             return
    //         } else {
    //             newUrl = '/' + lang + '/' + (urlParts[4] ? urlParts[4] : 'home')
    //             langPart = urlParts[3]
    //         }
    //     }

    //     if (langPart !== lang) {
    //         navigate(newUrl)
    //     }
    // }, [lang, navigate])

    console.log('lang', lang)
    return (
        <div className="!bg-white">
            {/* <RealmProvider> */}
            <Theme>
                <Layout />
            </Theme>
            {/* </RealmProvider> */}
        </div>
    )
}

export default MyApp
