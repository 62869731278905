import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'i18next'

import { dateLocales } from 'locales/index-swik.js'
import dayjs from 'dayjs'
import { setLang } from 'store/locale/localeSlice'

function useLocale() {
    const locale = useSelector((state) => state.locale.currentLang)
    const dispatch = useDispatch()

    useEffect(() => {
        const formattedLang = locale?.replace(/-([a-z])/g, function (g) {
            return g[1].toUpperCase()
        })
        if (locale !== i18n.language) {
            i18n.changeLanguage(formattedLang)
        }

        dateLocales[formattedLang]().then(() => {
            dayjs.locale(formattedLang)
        })
    }, [locale])

    return locale
}

export default useLocale
