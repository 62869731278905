import React, { useMemo, Fragment } from 'react'
import { Table } from 'components/ui'
// import TableRowSkeleton from './loaders/TableRowSkeleton'
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    getExpandedRowModel,
    flexRender,
} from '@tanstack/react-table'

const { Tr, Td, TBody } = Table

const SubDataTable = ({ row, subColumns: columns }) => {
    const table = useReactTable({
        data: useMemo(() => [...row], []),
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
    })

    return (
        <>
            <Table>
                <TBody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <Fragment key={row.id}>
                                <Tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <Td key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </Td>
                                        )
                                    })}
                                </Tr>
                            </Fragment>
                        )
                    })}
                </TBody>
            </Table>
        </>
    )
}

export default SubDataTable
